@import "../../styles/variables.scss";
.container {
	min-height: 70vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
}

.border {
	border-right: 1px solid lightgray;
}

.top__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 auto;

	max-width: $screen-sm;

	@media (max-width: $screen-sm) {
		flex-direction: column;
		font-size: 1.25rem;

		h1,
		span {
			font-size: medium;
		}
	}

	h1,
	span {
		font-size: medium;
		font-weight: 900;
		font-size: 2.25rem;

		letter-spacing: -0.025em;
		line-height: 1.1;
	}

	span {
		color: $color-secondary;
		@media (max-width: $screen-sm) {
			border-right: none;
		}
	}
}
